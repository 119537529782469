import React, { useState } from 'react'
import Layout from '../components/layout/Layout';
import { SocialIcons } from '../components/elements/SocialIcons';
import { social_links } from '../data/Navigation';
import RadioButton from '../components/elements/RadioButton';
import { isEmailValid, encode } from '../utils/General';
import { useForm } from "react-hook-form";
import SEO from '../components/SEO';


const Contact = props => {

    const { register, handleSubmit, watch, errors, formState } = useForm({ mode: 'onBlur' });

    const watchAllFields = watch(); // watching every fields in the form

    const { isSubmitting, submitCount } = formState;
    const [formSubmitted, setFormSubmitted] = useState(false)


    const onSubmit = (data) => {

        if (data) {
            console.log("SEDNING")
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "contact-2020", ...data })
            })
                .then(() => { console.log("SUCCESS"); return setFormSubmitted(true) })
                .catch(error => console.log(error));
        }
    }


    const socialIcons = social_links.filter(link => !link.icon.includes("medium"))
    return (          
            <Layout>
            <SEO
                title="Contact me"
                description="Reach out, let's be friends. Want to collaborate? Build something new? Or simply find out more. Get in touch."
            />
                <section className="mx-auto max-w-screen-lg px-4 md:px-4 my-12 flex md:flex-row flex-col">
                    <div className="w-full md:w-1/2 space-y-4 px-4 md:px-6  py-4 md:mr-8 h-full bg-gray-200 ">
                        <h1 className="font-heading leading-relaxed text-4xl">Drop me a line or Find me online</h1>
                        <p>Now you know a lot about me, it is time to make an internet friend don’t you think? Let’s talk. </p>
                        <p>Whether you want to work together, pitch an idea, or just trade semi-inspiring quotes about work ethic and entrepreneurial life - fill out the form, send me an email, or reach out on Twitter, LinkedIn or Instagram. </p>
                        <SocialIcons
                            showText
                            className="flex-col flex text-gray-400 font-light justify-between"
                            itemClassName="hover:text-gray-800 space-x-4"
                            icons={socialIcons}
                        />
                    </div>

                    {formSubmitted ?
                        <div className="w-full md:w-1/2 md:p-4 rounded  py-4 text-center my-12">
                            <h3> Thanks for reaching out. I'll respond ASAP.</h3>
                        </div> :
                        <form name="contact-2020" method="POST" data-netlify="true" onSubmit={handleSubmit(onSubmit)}
                            className="w-full md:w-1/2 md:p-4 space-y-2 rounded  py-4">
                            <div className="flex-col flex ">
                                <label className="font-normal" htmlFor="name">Introduce yourself...</label>
                                {/* include validation with required or other standard HTML validation rules */}
                                <input
                                    className={`w-full block my-3 text-base bg-gray-200 py-2 pl-4 ${errors.name ? "border-2 border-red-300" : ""}`}
                                    name="name"
                                    placeholder="Your name"
                                    ref={register({ required: true })}
                                />
                                <small className="text-red-500">{errors.name && "I'm going to need your name - how else would I know what to call you?!"}</small>
                            </div>
                            <div className="flex-col flex">
                                <h3 className="text-lg font-normal" htmlFor="name">Pick your icebreaker...</h3>
                                <RadioButton
                                    name="Icebreaker"
                                    value="Sam, did you know you share your name with a famous mathematician?"
                                    register={register}
                                    active={watchAllFields.Icebreaker === "Sam, did you know you share your name with a famous mathematician?"}
                                    description="Sam, did you know you share your name with a famous mathematician?"
                                />
                                <RadioButton
                                    name="Icebreaker"
                                    value="Sam, do you know how much a polar bear weighs? Enough to break the ice."
                                    register={register}
                                    active={watchAllFields.Icebreaker === "Sam, do you know how much a polar bear weighs? Enough to break the ice."}
                                    description="Sam, do you know how much a polar bear weighs? Enough to break the ice."
                                />
                                <RadioButton
                                    name="Icebreaker"
                                    value="Sam, this form is awkward, why would you make something like this?"
                                    register={register}
                                    active={watchAllFields.Icebreaker === "Sam, this form is awkward, why would you make something like this?"}
                                    description="Sam, this form is awkward, why would you make something like this?"
                                />
                            </div>
                            <div className="flex-col flex ">
                                <h3 className="font-normal text-lg" htmlFor="name">Tell me your goals for this relationship...</h3>
                                <RadioButton
                                    name="Goals"
                                    value="Friend zone"
                                    register={register}
                                    active={watchAllFields.Goals === "Friend zone"}
                                    description="Friend zone"
                                />
                                <RadioButton
                                    name="Goals"
                                    value="Work zone"
                                    register={register}
                                    active={watchAllFields.Goals === "Work zone"}
                                    description="Work zone"
                                />
                                <RadioButton
                                    name="Goals"
                                    value="A little bit of both"
                                    register={register}
                                    active={watchAllFields.Goals === "A little bit of both"}
                                    description="A little bit of both"
                                />
                            </div>
                            <div className="flex-col flex ">
                                <label className="font-normal" htmlFor="message">Write your letter or just a message...</label>
                                <textarea
                                    className={`w-full resize-y block my-3 text-base bg-gray-200 py-2 px-4 ${errors.message ? "border-2 border-red-300" : ""}`}
                                    name="message"
                                    placeholder="Despite your form I am interested in working with you, or finding out more about..."
                                    ref={register({ required: true })}
                                />
                                <small className="text-red-500">
                                    {errors.message && "All great relationships start with a conversation."}
                                </small>

                            </div>
                            <div className="flex-col flex ">
                                <label className="font-normal" htmlFor="email">How can I reach you?</label>
                                <input
                                    className={`w-full block text-base my-3 bg-gray-200 py-2 pl-4 ${errors.email ? "border-2 border-red-300" : ""}`}
                                    name="email"
                                    placeholder="email@example.com"
                                    ref={register({ required: true, validate: input => isEmailValid(input) })}
                                />
                                <small className="text-red-500">{errors.email && "Communication is a two-way street, how else would I contact you?"}</small>
                            </div>
                            {
                                isSubmitting ?
                                    <button
                                        disabled
                                        className="w-full text-center py-1 font-thin  opacity-50 ">
                                        Submitting
                        </button> :
                                    <button
                                        className="w-full text-center py-1 font-thin  hover:bg-gray-300">
                                        Submit
                        </button>
                            }
                        </form>
                    }
                </section>
            </Layout>
    )
}
export default Contact
