import React from 'react'
import PropTypes from 'prop-types'

const RadioButton = ({ register, name, value, header, description, className="", active }) => {
    return (
        <div className={`py-3 -mt-1  flex relative pl-12 ${className} ${active ? 'bg-gray-200' :"bg-gray-100 " }`}>
            <label className="text-sm font-normal pr-4 flex w-full" >
            <input 
                className="radio-button md:p-1 mx-4 block self-center absolute left-0" 
                type="radio" 
                name={name} 
                value={value} 
                ref={(options) => register(options)} 
            />
            <div>
            {header} {description ? <p className="font-thin w-full">{description}</p>:""}
            </div>
         
            </label>
        </div>
    )
}

RadioButton.propTypes = {

}

export default RadioButton
